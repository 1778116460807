import { FC, memo } from 'react'

import { Text, Icon } from '@cash-me/react-components'

import { Infos, Section } from 'components/general-styles'
import { Grid, Col } from 'components/grid'

import { BenefitsProps } from './benefits.model'
import * as S from './styles'

export const Benefits: FC<BenefitsProps> = memo(({ children, benefits, columns = 2 }) => (
  <Section>
    <Grid col={{ phone: 1, tablet: 1 }}>
      <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
        <Infos>{children}</Infos>
        <S.BenefitList columns={columns}>
          {benefits.map(({ id, iconName, title, description }) => (
            <S.Benefit key={id}>
              <S.BenefitsIcon>
                <Icon
                  /*
                  // @ts-ignore */
                  name={iconName}
                  color="var(--colors-cyan-icon-default)"
                />
              </S.BenefitsIcon>
              <Text as="h3" size="heading4" weight="bold">
                {title}
              </Text>
              <Text size="body1" color="var(--colors-neutral-text-weak)">
                {description}
              </Text>
            </S.Benefit>
          ))}
        </S.BenefitList>
      </Col>
    </Grid>
  </Section>
))

Benefits.displayName = 'Benefits'
