import styled from 'styled-components'

import { theme } from 'styles/theme'

const { media, colors, radii, space } = theme

export const BenefitList = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${space['spacing-xxl']};

  @media (${media.tablet}) {
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    gap: ${space['spacing-xl']} 5.75rem;
  }
`

export const Benefit = styled.div`
  h3 {
    margin-bottom: ${space['spacing-sm']};
  }

  br {
    display: none;
  }

  @media (${media.desktop}) {
    br {
      display: block;
    }
  }
`

export const BenefitsIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space['spacing-xl']};
  height: ${space['spacing-xl']};
  margin-bottom: ${space['spacing-sm']};
  border-radius: ${radii['corner-radius-circular']};
  background-color: ${colors['neutral-background-medium']};
`
