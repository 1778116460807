export const benefits = [
  {
    id: '1',
    iconName: 'moneyBag',
    title: 'O valor que você precisa',
    description: 'Com taxas até 12x mais baratas que outros tipos de crédito.'
  },
  {
    id: '2',
    iconName: 'star',
    title: 'Sem necessidade de avalista',
    description: 'Não é preciso ter fiador ou oferecer bens pessoais como garantia.'
  },
  {
    id: '3',
    iconName: 'clock',
    title: 'Ótimas condições de pagamento',
    description: 'Até 90 meses para pagar.'
  },
  {
    id: '4',
    iconName: 'person',
    title: 'Atendimento humanizado',
    description: 'Nossos consultores especializados vão te ajudar durante todo o processo.'
  }
]
