import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'

import { Benefits as BenefitsModule } from 'modules/benefits'

import { benefits } from './data'

const Benefits: FC = memo(() => (
  <BenefitsModule benefits={benefits}>
    <Text className="subtitle" size="caption" weight="medium" uppercase>
      Vantagens
    </Text>
    <Text as="h2" size="heading3" weight="medium">
      <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
        Vantagens
      </Text>{' '}
      do empréstimo para condomínio
    </Text>

    <Text className="description" color="var(--colors-neutral-text-weak)">
      Realize projetos nos condomínios sem impeditivo financeiro!
    </Text>
  </BenefitsModule>
))

Benefits.displayName = 'Benefits'

export default Benefits
